// React Required
import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import { render } from 'react-snapshot';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

// Home layout
//import Home from './views/Home';
//import About from "./elements/About";
import Contact from './views/Contact';
import error404 from './views/Error404';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import SimpleReactLightbox from 'simple-react-lightbox';
import Privacy from './views/Privacy';
import Home2 from './views/Home2';

// <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>

class Root extends Component {
  render() {
    return (
      <SimpleReactLightbox>
        <BrowserRouter basename={'/'}>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home2} />
            <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
            <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={Privacy} />
            <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
            <Route component={error404} />
          </Switch>
        </BrowserRouter>
      </SimpleReactLightbox>
    );
  }
}

render(<Root />, document.getElementById('root'));
serviceWorker.register();
