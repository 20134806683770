import React, { Component } from 'react';

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src="/assets/images/brand-gravity-media/TPQ-Logo.avif" alt="Gravity Media Client TPQ" />
          </li>
          <li>
            <img src="/assets/images/brand-gravity-media/nedc-logo.png" alt="Gravity Media Client NEDC" />
          </li>
          <li>
            <img
              src="/assets/images/brand-gravity-media/admin-logo.png"
              alt="Gravity Media Client PT121 Physical Therapy"
            />
          </li>
          <li>
            <img
              src="/assets/images/brand-gravity-media/ncd-logo.svg"
              alt="Gravity Media Client Natural & Cosmetic Dentistry"
            />
          </li>
          <li>
            <img src="/assets/images/brand-gravity-media/MC1.png" alt="Gravity Media Client Home Flipping Workshop" />
          </li>
          <li>
            <img src="/assets/images/brand-gravity-media/S98_Logo_Charcoal.png" alt="Gravity Media Client Studio98" />
          </li>
          <li>
            <img src="/assets/images/brand-gravity-media/TCL.png" alt="Gravity Media Client The Card Life TV" />
          </li>
          <li>
            <img
              src="/assets/images/brand-gravity-media/Agent-Link-Logo_800px.webp"
              alt="Gravity Media Client Agent Link"
            />
          </li>
          <li>
            <img src="/assets/images/brand-gravity-media/za.webp" height={60} alt="Gravity Media Client Zoe Abbott" />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
