import React, { Component } from 'react';

const ServiceList = [
  {
    icon: 'building',
    title: 'Corporate Videos',
    description:
      'Engaging cinematic corporate videos, interviews and bios for your website and business events. Your business visually explained to your clients is our focus.',
  },
  {
    icon: 'paper-plane',
    title: 'Commercials',
    description:
      'Whether you are selling a product or a service, we can produce content that will effortlessy sell your goods to your target audience.',
  },
  {
    icon: 'music',
    title: 'Social Media',
    description:
      'We work with influencers that have massive followings on Facebook, Instagram and TikTok to produce content and promote their products.',
  },
  {
    icon: 'film',
    title: 'Documentary / Film',
    description:
      'We are equipped to produce short films, documentaries and features. We have a network of screenwriters, a storyboard artist and other crew to help realize your vision.',
  },
];

class ServiceTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">Services</h2>
              <p>
                Do you need video production services ranging from internet ads, social media content and/or corporate
                content?
              </p>
              <p>
                Currently, video is a primary format for marketing material, especially for small to medium businesses.
                Social media is the perfect place to feature your videos for maximum reach.
              </p>
              <p>
                Work with a team that is skilled at producing captivating content that will boost brand awareness and
                generate views.
              </p>
              <div className="service-btn">
                <a className="btn-transparent rn-btn-dark" href="/contact" title="Request Custom Service">
                  <span className="text">Request Custom Service</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    title={val.title}
                  >
                    <div className="service service__style--2">
                      <div className="mb-2">
                        <i className={`fa fa-${val.icon} fa-3x red`} />
                      </div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
