import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';
import ContactTwo from '../elements/contact/ContactTwo';
import BrandTwo from '../elements/BrandTwo';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import HeaderMain from '../component/header/HeaderMain';
import Footer from '../component/footer/Footer';

class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Contact Gravity Media"
          description="Videographer in Clearwater, Tampa Video Production"
          meta="Gravity Media provides videography services in Clearwater, Tampa, St. Pete and Dunedin."
        />

        <HeaderMain headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 bg_image gravitymedia_bg" data-black-overlay="6">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Contact Us</h2>
                  <p>Reach out to us for your next video project.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <h1 className="text-center h5 mb-2">Videographer in Clearwater, Tampa, St. Pete, Dunedin</h1>

            <p className="text-center mb-5">
              You can reach us using the phone number or email address below, or fill out our{' '}
              <a href="#contact-form" title="Contact Form">
                contact form
              </a>
              .
            </p>

            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Office Info</h4>
                    <p>
                      <a href="tel:+7277399939">(727) 739-9939</a>
                    </p>
                    <p>M-F 3PM-9PM, Sat 9AM-6PM</p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Email Address</h4>
                    <p>
                      <a href="mailto:info@gravitymedia.net" title="Contact Email Address">
                        info@gravitymedia.net
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://tampabaypodcaststudio.com"
                        title="Gravity Media Podcast Studio Website"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        tampabaypodcaststudio.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Location</h4>
                    <p>
                      300 S Madison Ave, Ste 6 <br /> Clearwater, FL 33756
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <a id="contact-form" />
          <ContactTwo />
        </div>
        {/* End Contact Page Area  */}

        {/* Start Contact Map  */}
        <div className="rn-contact-map-area position-relative">
          <div style={{ height: '650px', width: '100%' }}>
            <iframe
              title="Gravity Media Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d880.8188373710852!2d-82.7557073429666!3d27.98547097968373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2f1563f25236d%3A0xce88c43d3311e312!2sGravity%20Media!5e0!3m2!1sen!2sus!4v1627305236067!5m2!1sen!2sus"
              width="100%"
              height="650"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
        {/* End Contact Map  */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Contact;
