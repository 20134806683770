import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Footer from '../component/footer/Footer';
import CreativePortfolioGallery from '../component/HomeLayout/homeOne/CreativePortfolioGallery';
import ServiceTwo from '../elements/service/ServiceTwo';
import CounterOne from '../elements/counters/CounterOne';
import Testimonial from '../elements/Testimonial';
import About from '../component/HomeLayout/homeOne/About';
import BrandTwo from '../elements/BrandTwo';
import Helmet from '../component/common/Helmet';
import VideoButton from '../elements/Video';
import ModalVideo from 'react-modal-video';
import { VideoTag } from 'react-video-tag';
import HeaderGravity from '../component/header/HeaderGravity';

const SlideList = [
  {
    textPosition: 'text-left',
    category: '',
    title: '',
    description: 'Podcasting, social media, and video content production for the Tampa Bay Area.',
    buttonText: 'Contact Us',
    buttonLink: '/contact',
  },
];

class Home2 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div className="active-dark">
        <Helmet
          pageTitle="Gravity Media LLC"
          description="Video Production, Social Media Content Creation | Clearwater, Tampa Bay, St. Pete"
          meta="Gravity Media is a video production company providing the Tampa Bay with commercial video services. We have 13 years of experience in the industry and our services include corporate, documentary and commercial production."
        />
        {/* Start Header Area  */}
        <HeaderGravity />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center"
              key={index}
              data-black-overlay="6"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ''}
                      {
                        <h1 className="title">
                          {value.title || (
                            <span>
                              Welcome to <br />
                              Gravity Media
                            </span>
                          )}
                        </h1>
                      }
                      {value.description ? <p className="description">{value.description}</p> : ''}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="video-inner">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="u3MNh79jpL0"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button className="video-popup theme-color" onClick={this.openModal}>
                        <span className="play-icon"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="video-background">
                <VideoTag
                  autoPlay={`${true}`}
                  muted={`${true}`}
                  playsInline={`${true}`}
                  loop={`${true}`}
                  src={`${'/assets/video/GMHero_2024_XS_v3.mp4'}`}
                  poster={`${'/assets/images/gravity-media/GM-Thumbnail.png'}`}
                />
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area about-position-top pb--120 bg_color--1">
          <About />
        </div>
        {/* End About Area */}

        {/* Start Service Area  */}
        <div className="portfolio-area bg_image bg_image--3">
          <h2 className="text-center py-5">Our Recent Projects</h2>
          <CreativePortfolioGallery />
        </div>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pt-5 pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mt-5">
                  <h3 className="fontWeight500">Why Choose Gravity Media?</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Brand Area  */}
        <div className="rn-brand-area brand-separation bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <Footer />
        <VideoButton />
      </div>
    );
  }
}
export default Home2;
