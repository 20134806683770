import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import HeaderMain from '../component/header/HeaderMain';
import Footer from '../component/footer/Footer';

class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet
          pageTitle="Gravity Media Privacy Policy"
          description="Information regarding Privacy"
          meta="Gravity Media provides videography services in Clearwater, Tampa, St. Pete and Dunedin."
        />

        <HeaderMain headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--120 pb--190 bg_image gravitymedia_bg" data-black-overlay="6">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className=" theme-gradient">Privacy Policy</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <h1 className="text-center h5 mb-2">Here is Our Privacy Policy</h1>

            <p className="text-center mb-5">
              For any questions or concerns, please reach out to us on our contact page.
            </p>

            <div>
              <p>
                Your privacy is important to us. It is Gravity Media’s policy to respect your privacy regarding any
                information we may collect from you across our website, https://gravitymedia.net, and other sites we own
                and operate.
              </p>

              <p>
                We only ask for personal information when we truly need it to provide a service to you. We collect it by
                fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and
                how it will be used.
              </p>

              <p>
                We only retain collected information for as long as necessary to provide you with your requested
                service. What data we store, we’ll protect within commercially acceptable means to prevent loss and
                theft, as well as unauthorized access, disclosure, copying, use or modification.
              </p>

              <p>
                We don’t share any personally identifying information publicly or with third-parties, except when
                required to by law.
              </p>

              <p>
                Our website may link to external sites that are not operated by us. Please be aware that we have no
                control over the content and practices of these sites, and cannot accept responsibility or liability for
                their respective privacy policies.
              </p>

              <p>
                You are free to refuse our request for your personal information, with the understanding that we may be
                unable to provide you with some of your desired services.
              </p>

              <p>
                Your continued use of our website will be regarded as acceptance of our practices around privacy and
                personal information. If you have any questions about how we handle user data and personal information,
                feel free to contact us.
              </p>

              <p>This policy is effective as of January 1, 2024.</p>
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Privacy;
