import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';

class VideoButton extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    const { children, ...atts } = this.props;

    return (
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="u3MNh79jpL0"
          onClose={() => this.setState({ isOpen: false })}
        />
        <a
          {...atts}
          href="/"
          title="Our Work Portfolio"
          onClick={(e) => {
            e.preventDefault();
            window.scroll(0, 0);
            this.openModal();
          }}
        >
          {children}
        </a>
      </React.Fragment>
    );
  }
}
export default VideoButton;
