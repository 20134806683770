import React from 'react';
import PortfolioVideo from "../../../elements/PortfolioVideo";

const PortfolioList = [
    {
        images: 'que-rico-keto.png',
        category: 'Social Media',
        title: '¡Qué Rico! Keto E-book',
        videoId: 'wCpI3xOL6Gc'
    },
    {
        images: 'agent-link.png',
        category: 'Corporate',
        title: 'Agent Link Marketing',
        videoId: 'vZKo5hcuuGU'
    },
    {
        images: 'nedc1.png',
        category: 'Promotion',
        title: 'New England Design & Construction',
        videoId: '7M7KZLyNjOU'
    },
    {
        images: 'nedc2.png',
        category: 'Promotion',
        title: 'NEDC Client Introduction',
        videoId: '6MHIzzLUnxY'
    },
    {
        images: 'tpq.png',
        category: 'Product',
        title: 'The Pasta Queen Collection',
        videoId: 'qO83cWqxRCA'
    },
    {
        images: 'tpq2.png',
        category: 'Social Media',
        title: 'The Pasta Queen',
        videoId: 'xsBXqD2PqZI'
    },
    {
        images: 'visavis.png',
        category: 'Documentary',
        title: 'Vis a Vis Jewelry',
        videoId: 'NuEU_oUyDNM'
    },
    {
        images: 'onetoone.png',
        category: 'Promotional',
        title: 'One to One Physical Therapy',
        videoId: 'Gi483YowEo0'
    },
]


const CreativePortfolio = () => {

    const handleClick = (e) => {
        e.preventDefault();
    }

    return (
        <div>
            {/* Start Portfolio Area  */}
            <div className="creative-portfolio-wrapper bg_color--1">
                <div className="creative-portfolio-wrapper plr--10">
                    <div className="row row--5">
                        {PortfolioList.map((value , i ) => (
                            <div className="col-lg-3 col-md-6 col-12" key={i}>
                                <div className="portfolio-style--3">
                                    <div className="thumbnail">
                                        <a href="/" onClick={handleClick} title={`Portfolio Project: ` + value.title}>
                                            <img className="w-100" src={`/assets/images/gm-portfolio/portfolio-${value.images}`} alt="Portfolio Images"/>
                                        </a>
                                    </div>
                                    <div className="content" style={{zIndex: 50}}>
                                        <p className="portfoliotype">{value.category}</p>
                                        <h4 className="title"><a href="/" onClick={handleClick}>{value.title}</a></h4>
                                        <div className="portfolio-btn">
                                            <PortfolioVideo videoURL={value.videoId}>
                                                <a className="rn-btn text-white" href="/" title={`Portfolio Project: ` + value.title} onClick={handleClick}>Watch</a>
                                            </PortfolioVideo>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* End Portfolio Area  */}      
        </div>
    )
}

export default CreativePortfolio;