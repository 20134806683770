import React, { Component } from "react";
import ModalVideo from 'react-modal-video'

class  PortfolioVideo extends Component {
    constructor () {
        super()
        this.state = {
          isOpen: false,
        }
        this.openModal = this.openModal.bind(this)
    }
    
    openModal () {
        this.setState({isOpen: true});
        const body = window.document.body;
        if(body){
            body.classList.add('noscroll');
        }
    }

    render(){

        const {children, ...atts} = this.props;

        return(
            <React.Fragment>
                <ModalVideo 
                    channel='youtube' 
                    isOpen={this.state.isOpen} 
                    videoId={this.props.videoURL} 
                    onClose={() => {
                        this.setState({isOpen: false});
                        const body = window.document.body;
                        body.classList.remove('noscroll');
                    }} />
                <a {...atts}
                    href="/"
                    onClick={e=>{
                        e.preventDefault();
                        this.openModal();
                    }}>
                        {children}
                    </a>
            </React.Fragment>
        )
    }
}
export default PortfolioVideo;