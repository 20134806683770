import React, { Component } from 'react';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import VideoButton from '../../elements/Video';

const SocialShare = [
  { Social: <FaFacebookF />, link: 'https://www.facebook.com/gravitymediallc', title: 'Facebook' },
  { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/in/david-tinoco-a632a9a6/', title: 'LinkedIn' },
  { Social: <FaInstagram />, link: 'https://www.instagram.com/gravity__media', title: 'Instagram' },
  { Social: <FaTwitter />, link: 'https://twitter.com/gravitymediaweb', title: 'Twitter' },
];
class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Ready To Get Started?</span>
                    <h2>
                      Let's execute <br /> your video <br />
                      project!
                    </h2>
                    <a className="rn-button-style--2" href="/contact" title="Contact Us">
                      <span>Contact Us</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Quick Link</h4>
                        <ul className="ft-link">
                          <li>
                            <VideoButton>Our Work</VideoButton>
                          </li>
                          <li>
                            <a href="/contact" title="Contact us">
                              Contact Us
                            </a>
                          </li>
                          <li>
                            <a href="/privacy-policy" title="Privacy Policy">
                              Privacy Policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Connect</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:info@gravitymdia.net" title="Contact Email Address">
                              info@gravitymedia.net
                            </a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`} title={val.title}>
                                  {val.Social}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>Copyright © 2020-2024 Gravity Media LLC. All Rights Reserved.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
