import React, { Component } from 'react';
import { Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component {
  state = {
    reviewIndex: 0,
  };

  advanceTab() {
    let next = this.state.reviewIndex + 1;

    if (next > 11) {
      next = 0;
    }

    this.setState({
      reviewIndex: next,
    });
  }

  returnTab() {
    const next = this.state.reviewIndex - 1;

    if (next <= 0) return;

    this.setState({
      reviewIndex: next,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <h2 className="text-center mb-5">What Our Clients Say</h2>
            <Tabs selectedIndex={this.state.reviewIndex}>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>"Love working with Gravity Media. They are professional and can always deliver!"</p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Josh Friedman </span> - OWNER, ComicBookDirect.com
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "Gravity Media created a very professional brand video & documentary for me. I am an artist and
                      wanted to have a tool to introduce my artisan techniques and creations to my customers. They see
                      me differently after watching the video and they understand very clearly the amount of work and
                      creativity involved. It increases the value of my products. During the filming they gave simple,
                      concise, easy to follow instructions and made the experience fun."
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Vie Blakey </span> - OWNER, VIS A VIS Jewelry
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "Gravity Media have supported our agency for over a year now with video production, editing,
                      social media work and podcasting. David is incredibly focused on the industry of marketing and
                      social media and keeps himself on the pulse of what's working. He's not only helped my company but
                      also my client's along the way. You can lean on him for high quality marketing creativity and
                      support. And I need to mention, his studio he's built is top notch for podcasting and video
                      production."
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Cebron Walker </span> - OWNER, WALKER KREATIVE
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "We had an incredible experience at Gravity Media podcast studio. Filming podcasts there was
                      amazing! David and his team are exceptionally professional, ensuring a phenomenal end result.
                      Highly recommend this top-notch studio."
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Natalie Vaulin </span> - SALES MANAGER, BL BIO LAB
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "I've been working with Gravity Media for the last 2 years and I had a great experience producing
                      videos for my clients. The service was very professional. One of the videos got tens of thousands
                      of views on social media. They are great to work with really understand what you need and provide
                      it. I highly recommend!"
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Judy Bechar </span> - VOICE COACH
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "Gravity Media did a great job on videos I needed shot and was able to make it happen on short
                      notice. Definitely professionals and I would highly recommend them."
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Rafferty Pendery </span> - CEO, Studio98
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "I hire videographers across the country for projects, if I can’t be on location to shoot. Gravity
                      Media delivered exactly what I needed for my TV show at the exact specs I asked for. Great
                      quality, great communication, highly recommended!!!"
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Brandon Verzal </span> - PRODUCER, The Card Life TV
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "I have been working with Gravity Media for several years now and the thing that I am always
                      impressed with is their ability to capture a concept that I have and simplify it into a message
                      that communicates to the people that we want to work with.""
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>John Feulner </span> - OWNER, DPM Practice Solutions
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "I was very glad we decided to go with Gravity Media last Fall for the videos we needed for our
                      website. They kept all appointments, were professional, welcomed our input at each turn and
                      produced very well received, high quality videos. I really liked that they worked with us until
                      each video was given a “thumbs up.” They went to whatever lengths were needed to get the product
                      we all wanted. It made such a difference on our end.""
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Heather S. </span> - Marketing Director, Suncoast Rehab Center
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "We would definitely recommend you to others, and have already started doing that! Thank you so
                      much for your help!"
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Leslie Hobbs </span> - PRESIDENT, FUTURE OF HAITI ORGANIZATION
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "We are so happy with the final video and we are getting such a great response – our views on
                      YouTube just keep climbing and climbing! Just what we wanted, thank you!""
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Katrina Crocker </span> - OWNER, Do Good Creative
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      "Gravity Media created an incredible video for us to use in our sales to help enlighten possible
                      clients on our services. The video was professional and covered exactly what we needed in an easy
                      to understand way. The video has increased our sales by at least 50% and I wouldn’t think about
                      doing a presentation without it."
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Rick Garcia </span> - Senior Marketing Consultant, Galaxy Marketing Solutions.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabList className="testimonial-thumb-wrapper">
                <div className="text-center">
                  <div>
                    <a
                      href="/"
                      title="Previous Review Link"
                      onClick={(e) => {
                        e.preventDefault();
                        this.returnTab();
                      }}
                    >
                      <img
                        src="/assets/images/icons/slick-arrow-left.png"
                        title="Previous Review"
                        alt="Preview Review"
                      />
                    </a>
                    <a
                      href="/"
                      title="Next Review Link"
                      onClick={(e) => {
                        e.preventDefault();
                        this.advanceTab();
                      }}
                    >
                      <img
                        src="/assets/images/icons/slick-arrow-right.png"
                        title="Previous Review"
                        alt="Preview Review"
                        className="ml-5"
                      />
                    </a>
                  </div>
                </div>
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
